var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('header',[_c('div',{staticClass:"title"},[_c('h1',{staticClass:"m-0",attrs:{"data-testid":"charts-header-title"}},[_c(_setup.TabTitle,{attrs:{"show-child":false}},[_vm._v("\n          "+_vm._s(_vm.t('user.retention.edit.title.header'))+"\n        ")]),_vm._v(" "),_c('router-link',{attrs:{"to":{
            name: 'c-cluster-product-resource',
            params: {
              cluster: '_',
              product: 'auth',
              resource: 'management.cattle.io.user',
            }
          }}},[_vm._v("\n          "+_vm._s(_vm.t('user.retention.edit.title.pre'))+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.t('user.retention.edit.title.post'))+"\n      ")],1)])]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.t('user.retention.edit.title.subTitle')))])])
}
var staticRenderFns = []

export { render, staticRenderFns }